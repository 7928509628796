import Landing from './components/Landing';
import logo from './logo.svg';
import './style.css';
import { motion } from "framer-motion";

function App() {
  return (

    <Landing />

  );
}

export default App;
